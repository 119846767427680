import logo from "./logo.svg";
import "./App.css";
import CanvasTest from "./components/CanvasTest";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import ShowImage from "./components/ShowImage";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<CanvasTest />}/>
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/show" element={<ShowImage />} />
    </Routes>
  );
}

export default App;
