import React from "react";
import Resizer from "react-image-file-resizer";
import { Grid, Button, Checkbox, Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { ReactComponent as SaveIcon } from "./save_icon.svg";
import { ReactComponent as HomeIcon } from "./home_icon.svg";
import "./ImageProcess.css";
import AWS from "aws-sdk";
import axios from "axios";
const ShowImage = () => {

  const [selectedImage, setSelectedImage] = useState(null);
  const [tagState, setTagState] = useState(false);
  const [tagArray, setTagArray] = useState([]);
  const [values, setValues] = useState([]);
  const [tagStateArray, setTagStateArray] = useState([]);
  const [maskedImage, setMaskedImage] = useState(null);
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [allTagState, setAllTagState] = useState(false);
  const data = { selectedImage };
  const canvasRef = useRef(null);
  const canvas_Mask = useRef(null);
  const color_data = require("./color_data2.json");

  const [current, setCurrent] = useState(null)
  const [file, setFile] = useState(null);



  function convertImageToBase64(url) {
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  }
  const handleTagging = async () => {

    var i = new Image();

    i.onload = function () {
      setImageWidth(i.width);
      setImageHeight(i.height);
    };

    i.src = selectedImage;

    const res = await fetch("https://segfull-main.visualee.ai", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type: "base", image: selectedImage }),
    });
    console.log("Response", res);
    if (res.ok) {
      const response = await res.json();
      const json_data = JSON.parse(response.result);
      // console.log(Object.values(json_data))
      setTagArray(Object.keys(json_data));
      setValues(Object.values(json_data));
      setTagState(true);
      const tag_array = new Array(Object.keys(json_data).length).fill(false);
      setTagStateArray(tag_array);
    }
  };

  const handleTagChange = (idx) => {
    setTagStateArray((prevState) => {
      const newCheckedState = [...prevState];
      newCheckedState[idx] = !newCheckedState[idx];
      return newCheckedState;
    });
  };

  const handleAllTagChange = () => {
    const temp_Array = new Array(tagArray.length).fill(!allTagState);
    setTagStateArray(temp_Array);
    setAllTagState(!allTagState);
  };

  useEffect(() => {
    if (tagState) {
      console.log("Image Changed");
      const canvas = canvasRef.current;
      canvas.width = imageWidth;
      canvas.height = imageHeight;

      const ctx = canvas.getContext("2d");

      // Create a new Image object
      const img = new Image();
      // Set the source of the Image object to the base64 string
      img.src = selectedImage;

      // Add an event listener to ensure the image is loaded before drawing
      const canvas_mask = canvas_Mask.current;
      canvas_mask.width = imageWidth;
      canvas_mask.height = imageHeight;
      const ctx_mask = canvas_mask.getContext("2d");
      ctx_mask.fillStyle = "black";
      ctx_mask.fillRect(0, 0, imageWidth, imageHeight);
      img.onload = function () {
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

        // console.log(tagStateArray)
        for (let i = 0; i < tagArray.length; i++) {
          // console.log(tagArray[i], ":::", tagStateArray[i])
          if (tagStateArray[i] === true) {
            // console.log("MakeMasking", tagArray[i])
            drawMask(values[i], tagArray[i]);
          }
        }

        // Draw the mask after the image is loaded onto the canvas
      };
    }
  }, [selectedImage, tagArray, tagState, tagStateArray, values]);
  const drawMask = (contoursArray, tag_name) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const canvas_mask = canvas_Mask.current;
    const ctx_mask = canvas_mask.getContext("2d");
    const color = color_data[tag_name];

    ctx.fillStyle = color;
    ctx_mask.fillStyle = "white";
    ctx.beginPath();
    contoursArray.forEach((contour) => {
      ctx.moveTo(contour[0][0], contour[0][1]);
      ctx_mask.moveTo(contour[0][0], contour[0][1]);
      contour.forEach((point) => {
        ctx.lineTo(point[0], point[1]);
        ctx_mask.lineTo(point[0], point[1]);
      });

      ctx.fill();
      ctx_mask.fill();
    });
    ctx.closePath();
    // setMaskedImage(canvas_mask.toDataURL());
  };


  const setShowImage = async () => {
    const current = localStorage.getItem('current')
    console.log(current)

    convertImageToBase64(current)
    .then(base64String => {
      setSelectedImage(base64String);
    })
    .catch(error => {
      console.error('Error:', error);
    });
    // setSelectedImage(resizedImage);
  }

  useEffect(() => {
    setShowImage()
  }, [])


  const handleMove = () => {
    window.location.href = "/home";
  };

  return (
    <div className="container">
      <div style={{ marginTop: "30px", display: "flex" }}>
        <span
          onClick={handleMove}
          style={{ cursor: "pointer", marginRight: "80%", width: "10%" }}
        >
          <HomeIcon style={{ width: "32px" }} />
        </span>
      </div>

      {!tagState && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={selectedImage} alt="selected" />
          <Button
            variant="contained"
            sx={{ marginTop: "10px", backgroundColor: "#6962FF" }}
            onClick={handleTagging}
          >
            Tagging
          </Button>
        </div>
      )}
      {tagState && (
        <div style={{ marginTop: "30px" }}>
          {/* <span onClick={handleSave}><SaveIcon /></span> */}

          <br />
          <div style={{ position: "relative" }}>
            {/* Canvas which will display the original image with the mask */}
            <canvas ref={canvasRef}></canvas>
          </div>
          <br />
          <div
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "auto",
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
              gridRowGap: "10px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Button
                id="all"
                variant="contained"
                onClick={handleAllTagChange}
                sx={{
                  color: "#46FCFC",
                  backgroundColor: !allTagState ? "#000000" : "#FEE9FD",
                  border: "2px solid #46FCFC",
                }}
              >
                All
              </Button>
            </div>
            {tagArray.map((tag, index) => (
              <div key={index} className="checkbox-item">
                <Button
                  id={`tag-${index}`}
                  variant="contained"
                  onClick={() => handleTagChange(index)}
                  sx={{
                    color: "#FFFFFF",
                    backgroundColor: !tagStateArray[index]
                      ? "#000000"
                      : "#6962FF",
                    border: !tagStateArray[index]
                      ? "2px solid #FFFFFF"
                      : "none",
                  }}
                >
                  {tag.split(",")[0]}
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div style={{ display: "none" }}>
        {/* Canvas which will display the original image with the mask */}
        <canvas ref={canvas_Mask}></canvas>
      </div>
    </div>
  );
};

export default ShowImage;
