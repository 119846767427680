import axios from "axios";
import React, { useState, useEffect } from "react";

const Home = () => {
  const [data, setData] = useState([]);

  const getAllData = async () => {
    const config = {
      method: "get",
      url: process.env.REACT_APP_API + "/generate-image/getTagging/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  };

  useEffect(() => {
    getAllData()
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // Add empty dependency array to prevent infinite requests

  const handleImageClick = (item) => {
    console.log("Clicked image:", item);
    localStorage.setItem('current', item.origin)
    window.location.href = '/show';
    // You can add more actions here based on the clicked image
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      {data.map(
        (item, index) =>
          item.origin.startsWith("http") && (
            <img
              key={item.id}
              src={item.origin}
              alt={`Image ${index}`}
              style={{ width: "15%", margin: "1%", cursor:'pointer' }}
              onClick={() => handleImageClick(item)}
            />
          )
      )}
    </div>
  );
};

export default Home;
